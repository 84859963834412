import React, { Suspense } from 'react';

import { Layout } from '@components/layout';
import { Mypage } from '@features/mypage';
import { CouponsList } from '@features/mypage/CouponsList';

const CouponsPage = () => (
  <Layout>
    <Suspense fallback={<p>Loading...</p>}>
      <Mypage>
        <CouponsList />
      </Mypage>
    </Suspense>
  </Layout>
);

export default CouponsPage;
