import { navigate } from 'gatsby';
import React, { FC } from 'react';

import { Button } from '@components/controls/button';
import { Block } from '@components/layout/block';
import { Flex } from '@components/layout/flexbox';
import { VStack } from '@components/layout/vstack';
import { Heading } from '@components/typographies/heading';
import { Paragraph } from '@components/typographies/paragraph';
import { Span } from '@components/typographies/span';

export const CouponsList: FC = () => (
  <Block width="100%" padding="30px 30px">
    <Heading as="h2" fontSize="24px" lh="40px">
      いまお持ちのクーポ
    </Heading>
    <VStack spacing="30px" align="flex-start" py="30px">
      <Block borderBottom="1.4px solid #d9d9d9" width="100%">
        <Flex jc="flex-start">
          <Span color="#F39800" fontSize="40px" width="30px">
            ・
          </Span>
          <Paragraph fontSize="16px" fontWeight="700">
            新規登録ありがとうクーポン
          </Paragraph>
          <Paragraph fontSize="14px" fontWeight="500" color="navy" align="right">
            有効期限 2022/09/01-2023/02/01
          </Paragraph>
        </Flex>
      </Block>
      <Block borderBottom="1.4px solid #d9d9d9" width="100%">
        <Flex jc="flex-start">
          <Span color="#F39800" fontSize="40px" width="30px">
            ・
          </Span>
          <Paragraph fontSize="16px" fontWeight="700">
            お誕生日クーポン
          </Paragraph>
          <Paragraph fontSize="14px" fontWeight="500" color="navy" align="right">
            有効期限 2022/09/01-2023/02/01
          </Paragraph>
        </Flex>
      </Block>
      <VStack spacing="30px" align="flex-end" width="100%">
        <Button
          width="120px"
          color="black"
          bg="gray"
          radius="5px"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            navigate('/mypage/coupons/list');
          }}
        >
          <Span fontWeight="500">もっと見る{'>'}</Span>
        </Button>
      </VStack>
    </VStack>
  </Block>
);
