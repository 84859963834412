import styled, { CSSObject } from 'styled-components';

export type Props = {
  fontSize?: string;
  fontWeight?: string;
  width?: string;
  lh?: string;
  align?: CSSObject['textAlign'];
  mx?: string;
  my?: string;
  color?:
    | 'black'
    | 'white'
    | 'navy'
    | 'orange'
    | 'gray'
    | 'gray-700'
    | 'gray-800'
    | 'gray-900'
    | 'deep-red';
  md?: CSSObject;
  wb?: CSSObject['wordBreak'];
  whiteSpace?: CSSObject['whiteSpace'];
};

export const Paragraph = styled.p<Props>((_) => ({
  fontSize: _.fontSize ?? '14px',
  fontWeight: _.fontWeight ?? 400,
  width: _.width,
  lineHeight: _.lh ?? '1em',
  textAlign: _.align ?? 'left',
  marginLeft: _.mx,
  marginRight: _.mx,
  marginTop: _.my,
  marginBottom: _.my,
  wordBreak: _.wb ?? 'unset',
  color: `var(--color-${_.color ?? 'navy'})`,
  '@media (min-width: 630px)': _.md,
  whiteSpace: _.whiteSpace,
}));
